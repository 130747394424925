<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar flat dense class="elevation-3" color="secondary">
      <v-toolbar-title class="body-2 white--text">
        {{ this.$route.meta.subtitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        transition="slide-y-transition"
        bottom
        dark
        content-class="actions"
        offset-y
        :min-width="$vuetify.breakpoint.smAndUp ? 700 : 450"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            rounded
            class="mx-1 hidden-sm-and-down"
            color="primary"
            dark
            v-on="on"
            >Akcije</v-btn
          >
          <v-btn
            small
            rounded
            class="mx-1 hidden-md-and-up"
            color="primary"
            dark
            v-on="on"
          >
            <v-icon dark>more_vert</v-icon>
          </v-btn>
        </template>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-list>
                <v-list-item key="1" @click="dialogIc = true">
                  <v-list-item-title
                    >Kreiraj ponude za ind. članarinu</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="2" @click="dialogKc = true">
                  <v-list-item-title
                    >Kreiraj ponude za kol. članarinu</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="3" @click="dialogMic = true">
                  <v-list-item-title
                    >Kreiraj ponude za međ. ind. članarinu</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="4" @click="dialogMkc = true">
                  <v-list-item-title
                    >Kreiraj ponude za međ. kol. članarinu</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="6" @click="sendQuoteDialog = true">
                  <v-list-item-action>
                    <v-icon color="secondary">email</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Pošalji odabrane</v-list-item-title>
                </v-list-item>
                <v-list-item key="7" @click="sendQuoteAllDialog = true">
                  <v-list-item-action>
                    <v-icon color="secondary">all_inbox</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Pošalji sve</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list>
                <v-list-item key="9" @click="changeStatus(1)">
                  <v-list-item-title
                    >Označi odabrane ponude kao poslane</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="10" @click="changeStatus(2)">
                  <v-list-item-title
                    >Označi odabrane ponude kao prihvaćene</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="11" @click="changeStatus(0)">
                  <v-list-item-title
                    >Označi odabrane ponude kao kreirane</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="5" @click="downloadQuote">
                  <v-list-item-action>
                    <v-icon>get_app</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Preuzmi odabrane</v-list-item-title>
                </v-list-item>
                <v-list-item key="8" @click="deleteQuoteDialog = true">
                  <v-list-item-action>
                    <v-icon color="error">delete_forever</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Briši odabrane</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-menu>
      <v-btn small fab dark class="mx-1" color="primary" @click="reload">
        <v-icon dark>autorenew</v-icon>
      </v-btn>
      <v-dialog v-model="dialogIc" width="500">
        <v-card>
          <v-card-title>Generiranje ponuda za ind. članarinu</v-card-title>
          <v-card-subtitle>Odaberite godinu za članarinu</v-card-subtitle>
          <v-card-text>
            <v-select :items="yearList" v-model="year"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="generateMembershipQuotes(2)"
              >KREIRAJ PONUDE</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogIc = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogKc" width="500">
        <v-card>
          <v-card-title>Generiranje ponuda za kol. članarinu</v-card-title>
          <v-card-subtitle>Odaberite godinu za članarinu</v-card-subtitle>
          <v-card-text>
            <v-select :items="yearList" v-model="year"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="generateMembershipQuotes(4)"
              >KREIRAJ PONUDE</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogKc = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogMic" width="500">
        <v-card>
          <v-card-title>Generiranje ponuda za međ. ind. članarinu</v-card-title>
          <v-card-subtitle>Odaberite godinu za članarinu</v-card-subtitle>
          <v-card-text>
            <v-select :items="yearList" v-model="year"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="generateMembershipQuotes(5)"
              >KREIRAJ PONUDE</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogMic = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogMkc" width="500">
        <v-card>
          <v-card-title>Generiranje ponuda za međ. kol. članarinu</v-card-title>
          <v-card-subtitle>Odaberite godinu za članarinu</v-card-subtitle>
          <v-card-text>
            <v-select :items="yearList" v-model="year"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="generateMembershipQuotes(6)"
              >KREIRAJ PONUDE</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogMkc = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteQuoteDialog" width="500">
        <v-card>
          <v-card-title>Upozorenje</v-card-title>
          <v-card-text
            >Jeste li sigurni da želite obrisati sve odabrane
            ponude?</v-card-text
          >
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="error"
              @click="deleteQuote"
              >BRIŠI</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="deleteQuoteDialog = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="sendQuoteDialog" width="500">
        <v-card>
          <v-card-title>Upozorenje</v-card-title>
          <v-card-text
            >Jeste li sigurni da želite poslati odabrane ponude?</v-card-text
          >
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="error"
              @click="sendQuote"
              >POŠALJI</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="sendQuoteDialog = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="sendQuoteAllDialog" width="500">
        <v-card>
          <v-card-title>Upozorenje</v-card-title>
          <v-card-text
            >Jeste li sigurni da želite poslati sve ponude?</v-card-text
          >
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="error"
              @click="sendQuoteAll"
              >POŠALJI</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="sendQuoteAllDialog = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }">
      <v-progress-circular
        :size="50"
        color="secondary"
        indeterminate
        :class="{
          'loading-visible': this.loading,
          'loading-hidden': !this.loading
        }"
      ></v-progress-circular>
      <v-container
        fluid
        fill-height
        class="pt-0"
        :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
      >
        <v-row no-gutters align-space-around justify-center>
          <v-col cols="12">
            <v-tabs v-model="tabs" show-arrows>
              <v-tab>INDIVIDUALNE ČL.</v-tab>
              <v-tab>KOLEKTIVNE ČL.</v-tab>
              <v-tab>MEĐUNARODNE IND.ČL.</v-tab>
              <v-tab>MEĐUNARODNE KOL.ČL.</v-tab>
              <v-tab>DOGAĐAJI</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="quoteListIc"
                  ref="quoteListIc"
                  class="xl-table"
                  :headers="[
                    { text: ' ', value: 'invalid' },
                    { text: 'Broj', value: 'quote_num' },
                    { text: 'Platitelj', value: 'recipient', width: 250 },
                    { text: 'Status', value: 'status' },
                    { text: 'Iznos', value: 'amount_total', align: 'end' },
                    { text: 'Rok plaćanja', value: 'due_date' },
                    { text: 'Kreirana', value: 'created' },
                    { text: 'Poziv na broj', value: 'irn' }
                  ]"
                  apiLoad="quote/quotes.php?type=2"
                  :actions="[
                    {
                      id: '2',
                      name: 'Preuzmi PDF ponude',
                      apiDownloadCall: 'quote/quote.php?format=pdf',
                      icon: 'picture_as_pdf',
                      color: 'error'
                    }
                  ]"
                  defaultFilter="recipient"
                  multiSelect
                  @rowclick="rowClick"
                  :statusColorFunc="statusColorFunc"
                  defaultSort="quote_num"
                  defaultSortDesc
                  yearFilter
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="quoteListKc"
                  ref="quoteListKc"
                  class="xl-table"
                  :headers="[
                    { text: ' ', value: 'invalid' },
                    { text: 'Broj', value: 'quote_num' },
                    { text: 'Platitelj', value: 'recipient', width: 250 },
                    { text: 'Status', value: 'status' },
                    { text: 'Iznos', value: 'amount_total', align: 'end' },
                    { text: 'Rok plaćanja', value: 'due_date' },
                    { text: 'Kreirana', value: 'created' },
                    { text: 'Poziv na broj', value: 'irn' }
                  ]"
                  apiLoad="quote/quotes.php?type=4"
                  :actions="[
                    {
                      id: '1',
                      name: 'Uredi ponudu',
                      path: '/quoteedit/#id#',
                      icon: 'edit',
                      color: 'secondary'
                    },
                    {
                      id: '2',
                      name: 'Preuzmi PDF ponude',
                      apiDownloadCall: 'quote/quote.php?format=pdf',
                      icon: 'picture_as_pdf',
                      color: 'error'
                    }
                  ]"
                  defaultFilter="recipient"
                  multiSelect
                  @rowclick="rowClick"
                  :statusColorFunc="statusColorFunc"
                  defaultSort="quote_num"
                  defaultSortDesc
                  yearFilter
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="quoteListMic"
                  ref="quoteListMic"
                  class="xl-table"
                  :headers="[
                    { text: ' ', value: 'invalid' },
                    { text: 'Broj', value: 'quote_num' },
                    { text: 'Platitelj', value: 'recipient', width: 250 },
                    { text: 'Status', value: 'status' },
                    { text: 'Iznos', value: 'amount_total', align: 'end' },
                    { text: 'Rok plaćanja', value: 'due_date' },
                    { text: 'Kreirana', value: 'created' },
                    { text: 'Poziv na broj', value: 'irn' }
                  ]"
                  apiLoad="quote/quotes.php?type=5"
                  :actions="[
                    {
                      id: '1',
                      name: 'Uredi ponudu',
                      path: '/quoteedit/#id#',
                      icon: 'edit',
                      color: 'secondary'
                    },
                    {
                      id: '2',
                      name: 'Preuzmi PDF ponude',
                      apiDownloadCall: 'quote/quote.php?format=pdf',
                      icon: 'picture_as_pdf',
                      color: 'error'
                    }
                  ]"
                  defaultFilter="recipient"
                  multiSelect
                  @rowclick="rowClick"
                  :statusColorFunc="statusColorFunc"
                  defaultSort="quote_num"
                  defaultSortDesc
                  yearFilter
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="quoteListMkc"
                  ref="quoteListMkc"
                  class="xl-table"
                  :headers="[
                    { text: ' ', value: 'invalid' },
                    { text: 'Broj', value: 'quote_num' },
                    { text: 'Platitelj', value: 'recipient', width: 250 },
                    { text: 'Status', value: 'status' },
                    { text: 'Iznos', value: 'amount_total', align: 'end' },
                    { text: 'Rok plaćanja', value: 'due_date' },
                    { text: 'Kreirana', value: 'created' },
                    { text: 'Poziv na broj', value: 'irn' }
                  ]"
                  apiLoad="quote/quotes.php?type=6"
                  :actions="[
                    {
                      id: '1',
                      name: 'Uredi ponudu',
                      path: '/quoteedit/#id#',
                      icon: 'edit',
                      color: 'secondary'
                    },
                    {
                      id: '2',
                      name: 'Preuzmi PDF ponude',
                      apiDownloadCall: 'quote/quote.php?format=pdf',
                      icon: 'picture_as_pdf',
                      color: 'error'
                    }
                  ]"
                  defaultFilter="recipient"
                  multiSelect
                  @rowclick="rowClick"
                  :statusColorFunc="statusColorFunc"
                  defaultSort="quote_num"
                  defaultSortDesc
                  yearFilter
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="quoteListEvent"
                  ref="quoteListEvent"
                  class="xl-table"
                  :headers="[
                    { text: 'Broj', value: 'quote_num' },
                    { text: 'Platitelj', value: 'recipient', width: 250 },
                    { text: 'Status', value: 'status' },
                    { text: 'Iznos', value: 'amount_total', align: 'end' },
                    { text: 'Rok plaćanja', value: 'due_date' },
                    { text: 'Kreirana', value: 'created' },
                    { text: 'Poziv na broj', value: 'irn' }
                  ]"
                  apiLoad="quote/quotes.php?type=90"
                  :actions="[
                    {
                      id: '1',
                      name: 'Uredi ponudu',
                      path: '/quoteedit/#id#',
                      icon: 'edit',
                      color: 'secondary'
                    },
                    {
                      id: '2',
                      name: 'Preuzmi PDF ponude',
                      apiDownloadCall: 'quote/quote.php?format=pdf',
                      icon: 'picture_as_pdf',
                      color: 'error'
                    }
                  ]"
                  defaultFilter="recipient"
                  multiSelect
                  @rowclick="rowClick"
                  :statusColorFunc="statusColorFunc"
                  defaultSort="quote_num"
                  defaultSortDesc
                  :selectFilter="events"
                  selectFilterId="id"
                  selectFilterText="name_hr"
                  selectFilterLabel="Događaj"
                  selectFilterName="event"
                  yearFilter
                ></InteractiveTable>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar
        v-model="snackbar"
        right
        :timeout="3000"
        :color="this.snackbarType === 0 ? 'success' : 'error'"
        >{{ this.message }}</v-snackbar
      >
      <v-bottom-sheet v-model="rowInfo" inset hide-overlay scrollable>
        <v-sheet class="text-center">
          <v-btn class="mt-6" text color="red" @click="rowInfo = !rowInfo"
            >ZATVORI</v-btn
          >
          <div class="py-3">
            <span
              v-html="selectedRow ? selectedRow.recipient : '?'"
            ></span>
            <br />
            {{ "E-mail: " + (selectedRow ? selectedRow.email : "") }}
            {{ "; Adresa: " + (selectedRow ? selectedRow.address : "") }}
            {{ "; OIB: " + (selectedRow ? selectedRow.vat_id : "") }}
            <br />
            {{ "Članovi: " + (selectedRow ? selectedRow.users : "") }}
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import InteractiveTable from "@/components/InteractiveTable.vue";

export default {
  name: "QuoteList",
  data() {
    return {
      dialogIc: false,
      dialogKc: false,
      dialogMic: false,
      dialogMkc: false,
      year: new Date().getFullYear(),
      yearList: [],
      loading: false,
      snackbar: false,
      snackbarType: 0,
      message: "",
      rowInfo: false,
      selectedRow: null,
      deleteQuoteDialog: false,
      tabs: null,
      sendQuoteDialog: false,
      sendQuoteAllDialog: false,
      events: []
    };
  },
  components: {
    InteractiveTable
  },
  computed: {
    needsRefresh: function() {
      return this.$store.getters.quoteListNeedsRefresh;
    }
  },
  watch: {
    needsRefresh(needsRefresh) {
      if (needsRefresh) {
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      if (this.$refs.quoteListIc) this.$refs.quoteListIc.reload();
      if (this.$refs.quoteListKc) this.$refs.quoteListKc.reload();
      if (this.$refs.quoteListMic) this.$refs.quoteListMic.reload();
      if (this.$refs.quoteListMkc) this.$refs.quoteListMkc.reload();
      if (this.$refs.quoteListEvent) this.$refs.quoteListEvent.reload();
      this.$store.dispatch("quote_list_refreshed");
    },
    generateMembershipQuotes(qtype) {
      this.dialogIc = false;
      this.dialogKc = false;
      this.dialogMic = false;
      this.dialogMkc = false;
    
      this.loading = true;
      // generate quotes on server
      this.$http
        .post("quote/quotecollection.php", {
          params: {
            type: qtype,
            year: this.year
          }
        })
        .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message = "Ponude su uspješno generirane";
          } else {
            this.snackbarType = 1;
            this.message = "Greška u generiranju ponuda";
          }
          this.snackbar = true;
          this.reload();
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u generiranju ponuda: " + error;
          this.snackbar = true;
        });
    },
    sendQuote() {
      this.sendQuoteDialog = false;
      var idata = [];
      var selected = null;
      switch (this.tabs) {
        case 0:
          if (this.$refs.quoteListIc)
            selected = this.$refs.quoteListIc.selected;
          break;
        case 1:
          if (this.$refs.quoteListKc)
            selected = this.$refs.quoteListKc.selected;
          break;
        case 2:
          if (this.$refs.quoteListMic)
            selected = this.$refs.quoteListMic.selected;
          break;
        case 3:
          if (this.$refs.quoteListMkc)
            selected = this.$refs.quoteListMkc.selected;
          break;
        case 4:
          if (this.$refs.quoteListEvent)
            selected = this.$refs.quoteListEvent.selected;
          break;
      }
      if (selected && selected.length > 0) {
        for (let i = 0; i < selected.length; i++) {
          var element = selected[i];
          // data needed to generate mail for company quote
          idata.push({
            id: element.id // id of quote
          });
        }

        // generate mail collection for quotes on server
        this.$http
          .post("mail/mailcollection.php", {
            params: {
              type: "quote",
              quotedata: idata
            }
          })
          .then(response => {
            this.loading = false;
            if (this.snackbar) this.snackbar = false;
            if (response.data.success) {
              this.snackbarType = 0;
              this.message =
                "E-mail poruke za slanje su uspješno generiranje. E-mailovi će se poslati u pozadini.";
            } else {
              this.snackbarType = 1;
              this.message = "Greška u generiranju e-mailova za slanje";
            }
            this.snackbar = true;
          })
          .catch(error => {
            this.loading = false;
            if (this.snackbar) this.snackbar = false;
            this.snackbarType = 1;
            this.message = "Greška u pripremi e-mailova za slanje: " + error;
            this.snackbar = true;
          });
      }
    },
    sendQuoteAll() {
      // generate mail collection for all unsent quotes
      this.sendQuoteAllDialog = false;
      this.loading = true;
      this.$http
        .post("mail/mailcollection.php", {
          params: {
            type: "quoteall",
            qtype:
              this.tabs == 0
                ? 2
                : this.tabs == 1
                ? 4
                : this.tabs == 2
                ? 5
                : this.tabs == 3
                ? 6
                : this.tabs == 4
                ? 90
                : 0
          }
        })
        .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message =
              "E-mail poruke za slanje su uspješno generiranje. E-mailovi će se poslati u pozadini.";
          } else {
            this.snackbarType = 1;
            this.message = "Greška u generiranju e-mailova za slanje";
          }
          this.snackbar = true;
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u pripremi e-mailova za slanje: " + error;
          this.snackbar = true;
        });
    },
    downloadQuote() {
      var selected = null;
      switch (this.tabs) {
        case 0:
          if (this.$refs.quoteListIc)
            selected = this.$refs.quoteListIc.selected;
          break;
        case 1:
          if (this.$refs.quoteListKc)
            selected = this.$refs.quoteListKc.selected;
          break;
        case 2:
          if (this.$refs.quoteListMic)
            selected = this.$refs.quoteListMic.selected;
          break;
        case 3:
          if (this.$refs.quoteListMkc)
            selected = this.$refs.quoteListMkc.selected;
          break;
        case 4:
          if (this.$refs.quoteListEvent)
            selected = this.$refs.quoteListEvent.selected;
          break;
      }
      if (selected && selected.length > 0) {
        for (let i = 0; i < selected.length; i++) {
          var element = selected[i];
          this.loading = true;
          this.$http
            .get("quote/quote.php", {
              params: {
                format: "pdf",
                id: element.id
              },
              responseType: "blob"
            })
            .then(response => {
              this.loading = false;
              const fileName = response.headers["x-suggested-filename"];
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
            })
            .catch(error => {
              this.loading = false;
            });
        }
      }
    },
    deleteQuote() {
      this.deleteQuoteDialog = false;
      var selected = null;
      switch (this.tabs) {
        case 0:
          if (this.$refs.quoteListIc) {
            selected = this.$refs.quoteListIc.selected;
            this.$refs.quoteListIc.selected = [];
          }
          break;
        case 1:
          if (this.$refs.quoteListKc) {
            selected = this.$refs.quoteListKc.selected;
            this.$refs.quoteListKc.selected = [];
          }
          break;
        case 2:
          if (this.$refs.quoteListMic) {
            selected = this.$refs.quoteListMic.selected;
            this.$refs.quoteListMic.selected = [];
          }
          break;
        case 3:
          if (this.$refs.quoteListMkc) {
            selected = this.$refs.quoteListMkc.selected;
            this.$refs.quoteListMkc.selected = [];
          }
          break;
        case 4:
          if (this.$refs.quoteListEvent) {
            selected = this.$refs.quoteListEvent.selected;
            this.$refs.quoteListEvent.selected = [];
          }
          break;
      }

      if (selected && selected.length > 0) {
        for (let i = 0; i < selected.length; i++) {
          var element = selected[i];
          this.loading = true;
          this.$http
            .delete("quote/quote.php", {
              params: {
                id: element.id
              }
            })
            .then(response => {
              this.loading = false;
              if (this.snackbar) this.snackbar = false;
              if (response.data.success) {
                this.snackbarType = 0;
                this.message =
                  "Ponuda broj " +
                  response.data.quote_num +
                  " uspješno obrisana";
              } else {
                this.snackbarType = 1;
                this.message =
                  "Greška u brisanju ponude broj " + response.data.quote_num;
              }
              this.snackbar = true;
              this.reload();
            })
            .catch(error => {
              this.loading = false;
              if (this.snackbar) this.snackbar = false;
              this.snackbarType = 1;
              this.message = "Greška u brisanju ponude: " + error;
              this.snackbar = true;
            });
        }
      }
    },
    changeStatus(status) {
      var selected = null;
      switch (this.tabs) {
        case 0:
          if (this.$refs.quoteListIc) {
            selected = this.$refs.quoteListIc.selected;
            this.$refs.quoteListIc.selected;
          }
          break;
        case 1:
          if (this.$refs.quoteListKc) {
            selected = this.$refs.quoteListKc.selected;
            this.$refs.quoteListKc.selected;
          }
          break;
        case 2:
          if (this.$refs.quoteListMic) {
            selected = this.$refs.quoteListMic.selected;
            this.$refs.quoteListMic.selected;
          }
          break;
        case 3:
          if (this.$refs.quoteListMkc) {
            selected = this.$refs.quoteListMkc.selected;
            this.$refs.quoteListMkc.selected;
          }
          break;
        case 4:
          if (this.$refs.quoteListEvent) {
            selected = this.$refs.quoteListEvent.selected;
            this.$refs.quoteListEvent.selected;
          }
          break;
      }

      if (selected && selected.length > 0) {
        for (let i = 0; i < selected.length; i++) {
          var element = selected[i];
          this.loading = true;
          this.$http
            .put("quote/quote.php", {
              params: {
                id: element.id,
                new_status: status
              }
            })
            .then(response => {
              this.loading = false;
              if (this.snackbar) this.snackbar = false;
              if (response.data.success) {
                this.snackbarType = 0;
                this.message = "Status ponude uspješno izmjenjen";
              } else {
                this.snackbarType = 1;
                this.message = "Greška u promjeni statusa ponude";
              }
              this.snackbar = true;
              this.reload();
            })
            .catch(error => {
              this.loading = false;
              if (this.snackbar) this.snackbar = false;
              this.snackbarType = 1;
              this.message = "Greška u promjeni statusa ponude: " + error;
              this.snackbar = true;
            });
        }
      }
    },
    rowClick(row) {
      this.selectedRow = row;
      this.rowInfo = true;
    },
    statusColorFunc(status) {
      var col = "white";
      switch (status) {
        case "KREIRANA":
          col = "secondary";
          break;
        case "POSLANA":
          col = "orange";
          break;
        case "PRIHVAĆENA":
          col = "primary";
          break;
        case "STORNIRANA":
          col = "error";
          break;
      }
      return col;
    }
  },
  mounted() {
    var y = new Date().getFullYear() + 1;
    for (var i = 0; i < 5; i++) {
      this.yearList.push(y - i);
    }
    // events
    var me = this;
    this.$http
      .get("event/events.php", {
        params: {}
      })
      .then(response => {
        me.events = response.data.rows;
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>

<style scoped>
.actions {
  margin-top: 10px;
  margin-right: 30px;
  background-color: #2196f3;
}
</style>
